import { createSlice } from '@reduxjs/toolkit';

// 초기 언어 상태 설정
const initialState = {
  language: localStorage.getItem('language') || 'ko'
};

// createSlice로 언어 상태 및 액션 정의
const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem('language', action.payload); // 상태가 변경될 때 localStorage 업데이트
    }
  }
});

// 액션 생성자와 리듀서 내보내기
export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;