import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userInfoReducer from './reducers/userInfo';
import userInfoReducer1 from './reducers/userInfo1';
import languageReducer from './reducers/languageSlice';


const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  userInfo1: userInfoReducer1,
  language: languageReducer,
})

const reducer = persistReducer(persistConfig, rootReducer);

export const store =  configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
  // 기본 값이 true지만 배포할때 코드를 숨기기 위해서 false로 변환하기 쉽게 설정에 넣어놨다.
  devTools: true,
});

export const persistor = persistStore(store);