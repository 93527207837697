import React from 'react';
import { useSelector } from 'react-redux';
import { translations } from '../data/translation'; // Import translations
import authrium_logo from "../images/authrium_logo.png";

export default function Footer() {
    const language = useSelector((state) => state.language.language); // Get current language from Redux store
    const t = translations[language]; // Get the translation object based on the selected language

    return (
    <>
        <footer>
            <section className="footer_box">
                <div className="footer_001">
                    <img src={authrium_logo} alt={t.logoAlt} />
                </div>
                <div className="footer_002">
                    <ul className="footer_003">
                        <li><a href="">{t.termsOfService}</a></li>
                        <li><a href="">{t.privacyPolicy}</a></li>
                    </ul>
                    <div className="footer_004">
                        <a href={`mailto:${t.emailContact}`}>{t.emailContact}</a>
                        <p>{t.copyright}</p>
                    </div>
                </div>
            </section>
        </footer>
    </>
    );
}
