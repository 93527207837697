import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userLogin1, userLogout1 } from "../../reducers/userInfo1";
import { Config } from "../../common/config";
export default function UserConfirm(props) {
  const navigate = useNavigate();
  const userInfo1 = useSelector((state) => state.userInfo1);

  const goLogin = () => {
    userLogout1();
    window.location.href = `${Config.SHOPADMIN_SERVER}login`;
  };

  useEffect(() => {}, []);

  return (
    <>
      <section className="location_box">
        <ul className="location_001">
          <li>Home</li>
          <li>회원가입 완료</li>
        </ul>
      </section>

      <main>
        <section className="contants_box_04">
          <article className="signup_07">
            <h2 className="tit_01">회원가입 완료</h2>
            <p>내기프트 회원으로 가입해주셔서 감사합니다.</p>
          </article>

          <article className="contants_box_03">
            <div className="signup_07_001">
              {userInfo1.name}님,&nbsp;내기프트에 가입해주셔서 감사합니다.
              <br />
              {userInfo1.name}님의 아이디는{" "}
              <span className="font_red">&nbsp;{userInfo1.id}&nbsp;</span>
              입니다.
              <br />
              본 아이디로 모든 서비스를 자유롭게 이용하실 수 있습니다.
              <br />
              더욱 다양한 서비스를 통해 회원만족을 실현하는 내기프트가
              되겠습니다.
            </div>
          </article>

          <article className="button_box_01">
            <NavLink to="/">
              <button
                type="button"
                className="button_01 button_03 button_gray_line"
              >
                Home
              </button>
            </NavLink>
            {/* <NavLink to='/login'> */}
            <button
              type="button"
              className="button_01 button_03 button_red"
              onClick={goLogin}
            >
              로그인
            </button>
            {/* </NavLink> */}
          </article>
        </section>
      </main>
    </>
  );
}
