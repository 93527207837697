import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { translations } from '../../data/translation'; // Import the translations
import { Config } from '../../common/config';

export default function UserSignUp(props) {
    const language = useSelector((state) => state.language.language); // Get current language from Redux store
    const t = translations[language]; // Get the translation object based on the selected language

    useEffect(() => {
        // Side effects on component load
    }, []);

    return (
        <>
            {/* <!--location:start--> */}
            <section className="location_box">
                <ul className="location_001">
                    <li>{t.home}</li>
                    <li>{t.signupPage}</li>
                </ul>
            </section>
            {/* <!--location:end--> */}

            {/* <!--contents:start--> */}
            <main>
                <section className="contants_box contants_box_02">
                    <h2 className="tit_01">{t.signupPage}</h2>

                    {/* <!--form section:start--> */}
                    <article className="contants_box_03">
                        <table className="member_box">
                            <tbody>
                                <tr>
                                    <td>
                                        <label className="check_box">{t.agreeToAll}
                                            <input type="checkbox" />
                                            <span className="check_check"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="signup_01">
                                            <label className="check_box"><span className="font_red">{t.serviceTerms}</span>
                                                <input type="checkbox" defaultChecked />
                                                <span className="check_check"></span>
                                            </label>
                                            <NavLink to='/user-agreement' className="button_04 button_gray_line">{t.viewAll}</NavLink>
                                        </div>
                                        <div className="signup_02 agreement_box">
                                            <iframe src={`${Config.SHOPADMIN_SERVER}agreement_wrap.html`}></iframe>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="signup_01">
                                            <label className="check_box"><span className="font_red">{t.privacyPolicyTitle}</span>
                                                <input type="checkbox" />
                                                <span className="check_check"></span>
                                            </label>
                                            <NavLink to='/user-privacy' className="button_04 button_gray_line">{t.viewAll}</NavLink>
                                        </div>
                                        <div className="signup_02 agreement_box">
                                            <iframe src={`${Config.SHOPADMIN_SERVER}privacy_wrap.html`}></iframe>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </article>
                    {/* <!--form section:end--> */}

                    {/* <!--button:start--> */}
                    <article className="button_box_01">
                        <NavLink to='/user-content'>
                            <button type="button" className="button_01 button_02 button_red">{t.nextButton}</button>
                        </NavLink>
                    </article>
                    {/* <!--button:end--> */}
                </section>
            </main>
            {/* <!--contents:end--> */}
        </>
    );
}
