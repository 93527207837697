// src/common/translation.js

export const translations = {
  en: {
    greeting: "You've been envious of starbucks gifts so far, haven't you?",
    headlineLine1: "Now create your own",
    headlineLine2: "Store gift",
    qrCodeTitleLine1: "Qr code and mobile",
    qrCodeTitleLine2: "Page for gifting",
    qrCodeDescriptionLine1: "By signing up, you get your own store's",
    qrCodeDescriptionLine2: "Qr code and mobile page for gifting!",
    promotionTitleLine1: "Promote your qr code",
    promotionTitleLine2: "Anywhere",
    promotionDescriptionLine1: "Promote your qr code on offline stores, online websites,",
    promotionDescriptionLine2: "Naver cafe, blog, map services, and any social site!",
    scanTitleLine1: "Anyone can scan the",
    scanTitleLine2: "Qr code to gift",
    scanDescriptionLine1: "Customers who love your store can scan the qr code",
    scanDescriptionLine2: "And gift it to anyone!",
    redeemTitleLine1: "Scan the customer's",
    redeemTitleLine2: "Gift qr code",
    redeemDescriptionLine1: "Scan the customer's gift qr code to verify",
    redeemDescriptionLine2: "And provide the gift upon successful verification!",
    salesTitleLine1: "Check sales status",
    salesTitleLine2: "In real-time",
    salesDescriptionLine1: "You can check the sales status in real-time",
    salesDescriptionLine2: "And process refunds for customers who have purchased gifts!",
    paymentTitleLine1: "Gift sales payment",
    paymentTitleLine2: "Conditions",
    paymentDescriptionLine1: "Gift sales payments are subject to escrow",
    paymentDescriptionLine2: "And will not be deposited immediately to the store.",
    paymentNoteLine1: "The deposit date follows the payment provider's schedule.",
    paymentNoteLine2: "It will be automatically deposited if the gift validity period expires.",
    paymentNoteLine3: "A service fee of 10% will be deducted upon deposit.",
    logoAlt: "Naegift",
    serviceIntro: "Service introduction",
    storeSupport: "Store support",
    logout: "Logout",
    myPage: "My page",
    login: "Login",
    signup: "Sign up",
    toggleLanguage: "Ko",
    termsOfService: "Terms of service",
    privacyPolicy: "Privacy policy",
    emailContact: "naegift@authrium.com",
    copyright: "Copyright by. Authrium co., ltd. all rights reserved.",
    
    // Added Translations for UserSignUp Page
    home: "Home",
    signupPage: "Sign Up",
    agreeToAll: "Agree to all terms",
    serviceTerms: "[Required] Terms of Service",
    viewAll: "View All",
    privacyPolicyTitle: "[Required] Privacy Policy",
    nextButton: "Next",
    modalHeader: "Modal Header", // Placeholder translation
    modalMainMsg: "Modal Main Message", // Placeholder translation
    home: "Home",
    signup: "Sign Up",
    adminInfo: "01. Admin Information",
    enterAdminInfo: "Please enter administrator information for using the Naegift service.",
    id: "ID",
    password: "Password",
    confirmPassword: "Confirm Password",
    name: "Name",
    birthday: "Date of Birth",
    gender: "Gender",
    male: "Male",
    female: "Female",
    email: "Email",
    emailAuthCode: "Email Authentication Code",
    phoneNumber: "Phone Number",
    phoneAuthCode: "Phone Authentication Code",
    address: "Address",
    receiveConsent: "SMS/Push/Email Consent",
    receiveAgree: "Agree",
    receiveDisagree: "Disagree",
    receiveInfo: "※ To use the Naegift service, you must agree to receive SMS/Push/Email notifications.",
    duplicateCheck: "Check Duplicate",
    requestAuthCode: "Request Auth Code",
    validateAuthCode: "Validate Auth Code",
    searchAddress: "Search",
    postalCode: "Postal Code",
    roadAddress: "Road Address",
    jibunAddress: "Jibun Address",
    detailAddress: "Detail Address",
    referenceItem: "Reference Item",
    cancel: "Cancel",
    next: "Next",

    // Modal Confirmations
    confirm: "Confirm",
    cancelText: "Cancel",
    alertMessage: "Message",
    passwordPlaceholder:"Combination of letters, numbers, and special characters, 8-16 characters long",
    emailPlaceholder: "EX)naegift@authrium.com",

    storeInfo: "Store Information",
    storeInfoDescription1: "Please provide store information for your gift service.",
    storeInfoDescription2: "This information will be visible to your customers.",
    storeName: "Store Name",
    storeLogo: "Store Logo",
    fileDescription: "※ You can attach jpg, jpeg, gif, and png files. You can upload one file up to 3MB.",
    phoneNumber: "Phone Number",
    sameAsAdmin: "Same as Admin Phone",
    storeInfoDescription1: "Please enter your store information and create a gift QR code to use the Naegift service.",
    storeInfoDescription2: "For individuals, please set up fictional store information to issue gifts.",
    selectBank: 'Select bank',
    bankAccountNumber: 'Bank account number',
    accountHolder: 'Account holder',
    giftQR: 'Gift QR code',
    downloadQR: 'Download gift QR code',
    printQR: 'Print gift QR code',
    createQR: 'Create gift QR code',
    bankAccountinput: "Please enter only numbers without '-'",
  },
  ko: {
    greeting: "그 동안 스타벅스 기프트를 보면서 부러워만 하셨죠?",
    headlineLine1: "이젠 우리 상점만의",
    headlineLine2: "기프트를 만들어 보세요",
    qrCodeTitleLine1: "선물하기 QR코드 및",
    qrCodeTitleLine2: "모바일 페이지 제공",
    qrCodeDescriptionLine1: "회원가입만 하면 우리 상점만의 선물하기",
    qrCodeDescriptionLine2: "QR코드 및 모바일 페이지가 생깁니다!",
    promotionTitleLine1: "선물하기 QR코드를",
    promotionTitleLine2: "어디에나 홍보하기",
    promotionDescriptionLine1: "선물하기 QR코드를 오프라인 매장, 온라인 홈페이지,",
    promotionDescriptionLine2: "네이버카페, 블로그, 지도서비스, 각종 소셜 사이트",
    scanTitleLine1: "어느누구나 선물하기",
    scanTitleLine2: "QR코드 스캔 후 선물하기",
    scanDescriptionLine1: "우리 상점을 아끼는 손님들은 선물하기 QR코드 스캔 후",
    scanDescriptionLine2: "누구에게나 우리 상점의 기프트를 선물할 수 있습니다!",
    redeemTitleLine1: "손님의 기프트",
    redeemTitleLine2: "QR코드 스캔 후 상품제공",
    redeemDescriptionLine1: "선물받은 손님의 기프트 QR코드를 스캔해서",
    redeemDescriptionLine2: "기프트를 검증하고 검증성공시 상품을 제공하세요!",
    salesTitleLine1: "판매현황 실시간",
    salesTitleLine2: "확인하기",
    salesDescriptionLine1: "기프트 판매현황을 실시간으로 볼 수 있고,",
    salesDescriptionLine2: "기프트를 구매한 손님들에게 환불 등을 할 수 있습니다!",
    paymentTitleLine1: "기프트 판매대금",
    paymentTitleLine2: "지급조건",
    paymentDescriptionLine1: "기프트 판매 대금은 에스크로가 적용되어 상점으로 즉시",
    paymentDescriptionLine2: "입금되지 않고 손님의 기프트 QR코드 스캔 후에",
    paymentNoteLine1: "입금일은 결제사 지급일에 따릅니다.",
    paymentNoteLine2: "기프트 유효기간 경과시에 자동입금됩니다.",
    paymentNoteLine3: "입금시 내기프트 서비스 이용료(10%)를 제하고 입금됩니다.",
    logoAlt: "내기프트",
    serviceIntro: "서비스소개",
    storeSupport: "상점지원",
    logout: "로그아웃",
    myPage: "마이페이지",
    login: "로그인",
    signup: "회원가입",
    toggleLanguage: "EN",
    termsOfService: "서비스이용약관",
    privacyPolicy: "개인정보취급방침",
    emailContact: "naegift@authrium.com",
    copyright: "Copyright by. ㈜오스리움. All Rights Reserved.",
    
    // Added Translations for UserSignUp Page
    home: "홈",
    signupPage: "회원가입",
    agreeToAll: "전체 약관에 동의합니다.",
    serviceTerms: "[필수] 서비스이용약관",
    viewAll: "전체보기",
    privacyPolicyTitle: "[필수] 개인정보취급방침",
    nextButton: "다음",
     // Previous translations here...

    // New translations for UserSignUp component
    modalHeader: "모달 헤더", // Placeholder translation
    modalMainMsg: "모달 메인 메시지", // Placeholder translation
    home: "홈",
    signup: "회원가입",
    adminInfo: "01.관리자 정보",
    enterAdminInfo: "내기프트 서비스 이용을 위해 관리자 정보를 입력 해 주세요.",
    id: "아이디",
    password: "비밀번호",
    confirmPassword: "비밀번호 확인",
    name: "이름",
    birthday: "생년월일",
    gender: "성별",
    male: "남자",
    female: "여자",
    email: "이메일",
    emailAuthCode: "이메일 인증번호",
    phoneNumber: "휴대폰번호",
    phoneAuthCode: "휴대폰번호 인증번호",
    address: "주소",
    receiveConsent: "SMS/푸시/이메일 수신동의",
    receiveAgree: "수신동의",
    receiveDisagree: "수신거부",
    receiveInfo: "※ 내기프트 서비스를 이용하기 위해서는 SMS/푸시/이메일 수신을 동의하셔야 합니다.",
    duplicateCheck: "중복확인",
    requestAuthCode: "인증번호받기",
    validateAuthCode: "인증하기",
    searchAddress: "찾기",
    postalCode: "우편번호",
    roadAddress: "도로명주소",
    jibunAddress: "지번주소",
    detailAddress: "상세주소",
    referenceItem: "참고항목",
    cancel: "취소",
    next: "다음",

    // Modal Confirmations
    confirm: "확인",
    cancelText: "취소",
    alertMessage: "메시지",
    passwordPlaceholder:"영문, 숫자, 특수문자 조합 8~16자",
    emailPlaceholder: "예)naegift@authrium.com",

    storeInfo: "상점 정보",
    storeInfoDescription1: "기프트 서비스를 위한 상점 정보를 입력해주세요.",
    storeInfoDescription2: "이 정보는 고객에게 표시됩니다.",
    storeName: "상점명",
    storeLogo: "상점 로고",
    fileDescription: "※ jpg,jpeg,gif,png 파일 첨부 가능, 1개 파일을 3MByte까지 업로드 할 수 있습니다.",
    phoneNumber: "전화번호",
    sameAsAdmin: "관리자 전화번호와 동일",
    storeInfoDescription1: "내기프트 서비스 이용을 위해 상점정보를 입력하고 선물하기 QR코드를생성해 주세요.",
    storeInfoDescription2: "개인의 경우는 기프트를 발행할 가상의 상점정보을 설정하시기바랍니다.",
    selectBank: '입금받을 은행',
    bankAccountNumber: '입금받을 계좌번호',
    accountHolder: '예금주',
    giftQR: '선물하기 QR 코드',
    downloadQR: '선물하기 QR코드 저장하기',
    printQR: '선물하기 QR코드 프린트하기',
    createQR: '선물하기 QR코드 생성하기',
    bankAccountinput: "'-' 없이 숫자만 넣으세요",
  }
};

