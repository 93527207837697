// src/components/Main.js

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { translations } from "../../data/translation";

import ng_main_01 from "../../images/ng_main_01.png";
import ng_main_02 from "../../images/ng_main_02.jpg";
import ng_main_03 from "../../images/ng_main_03.jpg";
import ng_main_04 from "../../images/ng_main_04.jpg";
import ng_main_05 from "../../images/ng_main_05.jpg";
import ng_main_06 from "../../images/ng_main_06.jpg";
import ng_main_07 from "../../images/ng_main_07.jpg";

import ng_main_01_eng from "../../images/ng_main_01_eng.png";
import ng_main_02_eng from "../../images/ng_main_02_eng.jpg";
import ng_main_03_eng from "../../images/ng_main_03_eng.jpg";
import ng_main_04_eng from "../../images/ng_main_04_eng.jpg";
import ng_main_05_eng from "../../images/ng_main_05_eng.jpg";
import ng_main_07_eng from "../../images/ng_main_07_eng.jpg";

export default function Main() {
    const [revealed, setRevealed] = useState(false);
    const language = useSelector((state) => state.language.language);
console.log(language)
    const reveal = () => {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 200;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", reveal);

        return () => {
            window.removeEventListener("scroll", reveal);
        };
    }, []);

    const t = translations[language]; // Get the translation based on the selected language

    return (
      <>
          {/* <!--메인상단이미지:start--> */}
          <section className="main_box_01">
              <div className="main_01_001 fadeinUp_01">
                  <small>{t.greeting}</small>
                  <h2>
                      {t.headlineLine1}
                      <br />
                      {t.headlineLine2}
                  </h2>
              </div>
              <img className="fadeinUp_02 main_box_02_img" src={language === "en" ? ng_main_01_eng : ng_main_01} />
          </section>
          {/* <!--메인상단이미지:end--> */}

          {/* <!--contants:start--> */}
          <main>
              <section className="contants_box contants_box_01">

                  {/* <!--QR Code Article--> */}
                  <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                      <div className="main_02_001">
                          <h3>
                              {t.qrCodeTitleLine1}
                              <br />
                              {t.qrCodeTitleLine2}
                          </h3>
                          <p>
                              {t.qrCodeDescriptionLine1}
                              <br />
                              {t.qrCodeDescriptionLine2}
                          </p>
                      </div>
                      <div>
                          <img className="main_box_02_img" src={language === "en" ? ng_main_02_eng : ng_main_02} alt='ng_main_02' />
                      </div>
                  </article>

                  {/* <!--Promotion Article--> */}
                  <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                      <div className="main_02_001">
                          <h3>
                              {t.promotionTitleLine1}
                              <br />
                              {t.promotionTitleLine2}
                          </h3>
                          <p>
                              {t.promotionDescriptionLine1}
                              <br />
                              {t.promotionDescriptionLine2}
                          </p>
                      </div>
                      <div>
                          <img className="main_box_02_img" src={language === "en" ? ng_main_03_eng : ng_main_03} alt='ng_main_03' />
                      </div>
                  </article>

                  {/* <!--Scan Article--> */}
                  <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                      <div className="main_02_001">
                          <h3>
                              {t.scanTitleLine1}
                              <br />
                              {t.scanTitleLine2}
                          </h3>
                          <p>
                              {t.scanDescriptionLine1}
                              <br />
                              {t.scanDescriptionLine2}
                          </p>
                      </div>
                      <div>
                          <img className="main_box_02_img"src={language === "en" ? ng_main_04_eng : ng_main_04} alt='ng_main_04' />
                      </div>
                  </article>

                  {/* <!--Redeem Article--> */}
                  <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                      <div className="main_02_001">
                          <h3>
                              {t.redeemTitleLine1}
                              <br />
                              {t.redeemTitleLine2}
                          </h3>
                          <p>
                              {t.redeemDescriptionLine1}
                              <br />
                              {t.redeemDescriptionLine2}
                          </p>
                      </div>
                      <div>
                          <img className="main_box_02_img" src={language === "en" ? ng_main_05_eng : ng_main_05} alt='ng_main_05' />
                      </div>
                  </article>

                  {/* <!--Sales Article--> */}
                  <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                      <div className="main_02_001">
                          <h3>
                              {t.salesTitleLine1}
                              <br />
                              {t.salesTitleLine2}
                          </h3>
                          <p>
                              {t.salesDescriptionLine1}
                              <br />
                              {t.salesDescriptionLine2}
                          </p>
                      </div>
                      <div>
                          <img className="main_box_02_img" src={ng_main_06} alt='ng_main_06' />
                      </div>
                  </article>

                  {/* <!--Payment Conditions Article--> */}
                  <article className={`reveal ${revealed ? 'active' : ''} main_box_02`}>
                      <div className="main_02_001">
                          <h3>
                              {t.paymentTitleLine1}
                              <br />
                              {t.paymentTitleLine2}
                          </h3>
                          <p>
                              {t.paymentDescriptionLine1}
                              <br />
                              {t.paymentDescriptionLine2}
                          </p>
                          <small>
                              {t.paymentNoteLine1}
                              <br />
                              {t.paymentNoteLine2}
                              <br />
                              {t.paymentNoteLine3}
                          </small>
                      </div>
                      <div>
                          <img className="main_box_02_img" src={language === "en" ? ng_main_07_eng : ng_main_07} alt='ng_main_07' />
                      </div>
                  </article>
              </section>
          </main>
          {/* <!--contants:end--> */}
      </>
  );
}